import React, {useState, useEffect} from 'react';
import { Button, Offcanvas, Card } from 'react-bootstrap';
import { useAppContext } from '../../AppContext';
import arrowLeft from '../../assets/arrow-left.svg'
import arrow from '../../assets/typcn_arrow-back-outline.svg'
import { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';
import { trackEvent } from '../../mixpanel';
import { transformPath } from '../../App';
import { useNavigate } from 'react-router-dom';
import orderRoute from '../../services/orderRoute';
import bag from '../../assets/bag.svg'
import greenBag from '../../assets/green-bag.svg'
import redTrash from '../../assets/red-trash.svg'
import productRoute from '../../services/productRoute'
import { localStorageGetItem, localStorageSetItem } from '../../App';
import SubscriptionModal from '../../components/Modal/SubscriptionModal';
import { trackAddToCart, trackAddToWishlist } from '../../services/tracking';


const Wishlist = () => {
  
    const navigate = useNavigate();
  const { handleShowWishlist, handleCloseWishlist, setShowWishlist, showWishlist, showToast, setWishlistItems, wishlistItems, setApiWishlistItems, apiWishlistItems, updatedWishlist, cartItems, setCartItems, updateCart}= useAppContext();
  const [loading, setLoading] = useState(false);
  const [showAuthButtons, setShowAuthButtons] = useState(false);
  const {getWishlist, updateWishlist, removefromWishlist}= orderRoute();
  const [subscriptionBoxes, setSubscriptionBoxes] = useState([]);
  const [globalProducts, setGlobalProducts] = useState([]);
  const [userBoxes, setUserBoxes] = useState([]);
  const {getGlobalProducts,getAllBoxes, getUserBoxes}= productRoute();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const userData = getParsedLocalStorageItem('userData');
  const userToken = localStorageGetItem
('userToken');
  const isLoggedIn = !!userData && !!userToken;

  useEffect(()=>{
   
    getAllBoxes(
      setLoading, showToast, setSubscriptionBoxes, 
     
    )
  
},[])

useEffect(() => {
  if (isLoggedIn) {
    getUserBoxes(
      setLoading, showToast, setUserBoxes, 
   
    )
  }
}, [isLoggedIn]); 

  useEffect(() => {
    if (isLoggedIn) {
      getWishlist(setLoading, showToast, setApiWishlistItems);
    } else {
      // Load wishlist from localStorage for unauthenticated users
      let storedWishlist = JSON.parse(localStorageGetItem
('wishlistItems')) || [];
      localStorageSetItem('wishlistItems', JSON.stringify(storedWishlist));
     
      setWishlistItems(storedWishlist);
    
    }
  }, [showWishlist, isLoggedIn]); 
  
  useEffect(()=>{
   
    getGlobalProducts(
      setLoading, showToast, setGlobalProducts, 
     
    )
  
},[])


  const handleDeleteFromWishlist = (productId) => {
  
    if (isLoggedIn) {
      removefromWishlist(productId, setLoading, showToast,apiWishlistItems, setApiWishlistItems);
     
    } 
    else 
    {
      const updatedWishlistItems = wishlistItems.filter(item => (item?.product?.id || item?.box?.id) !== productId);
      localStorageSetItem
('wishlistItems', JSON.stringify(updatedWishlistItems));
   
      setWishlistItems(updatedWishlistItems);
      const product = globalProducts.find(p => p.id === productId) || subscriptionBoxes.find(p => p.id === productId);
      trackEvent( `${product?.productName || product?.name} Removed from Wishlist`, {
        'Product ID': productId,
        'Product Name': product?.productName || product?.name,
        'Category Name': product?.category || '',
        'User': userData?.email || 'Anonymous',
     
      });
       
      trackAddToWishlist(productId, product?.productName || product?.name, 'Remove from Wishlist', userData && userData);
      showToast({
        toastText: 'Item removed from Wishlist',
        isSuccess: true
      });
  
    
    }
     // Debugging: Log the state and storage to confirm deletion
    
  };
  
  const handleAddtoCart = (id, increment = 1, replace = false) => {
    let productToAdd = null;
    let newCartItems = [...cartItems];
    const cartIndex = newCartItems.findIndex(item => item?.id === id);

    // Find the product in the wishlist items array
   
    const globalProduct =  globalProducts.find(p => p?.id === id) || subscriptionBoxes.find(box => box?.id === id)
    if (!globalProduct) {
        console.log('Error', `Product with ID ${id} not found in wishlist items`);
        return;
    }

    // Check if the globalProduct is out of stock
    if (globalProduct?.stockLevel === 'out_of_stock' || globalProduct?.quantityAvailable === 0) {
        showToast({ toastText: 'This product is currently out of stock.', isSuccess: false });
        return;
    }

  

    if (cartIndex !== -1) {
        // Item already in cart, update quantity
        const updatedQuantity = replace ? increment : newCartItems[cartIndex].quantity + increment;
        newCartItems[cartIndex] = { ...newCartItems[cartIndex], quantity: updatedQuantity };
    } else {
      const product = isLoggedIn ? apiWishlistItems.find(p => (p?.product?.id || p?.box?.id) === id) : wishlistItems.find(p => (p?.product?.id || p?.box?.id) === id);
      if (product?.product) {
        productToAdd = { ...product.product, id: product.product.id, quantity: increment };
    } else if (product?.box) {
        productToAdd = { ...product.box, id: product.box.id, quantity: increment };
    }

        newCartItems.unshift(productToAdd); // Adds at the beginning
    }

    setCartItems(newCartItems);
    updateCart(newCartItems);
    showToast({ toastText: '1 item added to Cart', isSuccess: true });

    // Tracking event after adding to cart
    if (!productToAdd) {
        // Find again if not found earlier
        productToAdd = isLoggedIn ? apiWishlistItems.find(p => (p?.product?.id || p?.box?.id) === id) : wishlistItems.find(p => (p?.product?.id || p?.box?.id) === id);
    }
    if (productToAdd) {
      // trackAddToCart(productToAdd.id, productToAdd.productName, productToAdd.price);
        trackEvent(`${productToAdd?.product?.productName || productToAdd?.box?.name} Added to Cart`, {
            'Product ID': productToAdd?.product?.id || productToAdd?.box?.id,
            'Product Name': productToAdd?.product?.productName || productToAdd?.box?.name,
            'Quantity Added': increment,
            'User': userData?.email || 'Anonymous',
            'Location': 'Wishlist'
        });
        trackAddToCart(productToAdd?.product?.id || productToAdd?.box?.id, productToAdd?.product?.productName || productToAdd?.box?.name, productToAdd?.product?.price || productToAdd?.box?.amount, 'Add to Cart', userData && userData) 
    }
};
 

 

const handleViewAll = ()=> {
  if (isLoggedIn) {
      
    handleCloseWishlist();
   
   navigate('/profile/wishlist')
  } else {
  
    setShowAuthButtons(true);
  }
}

const handleShowBoxModal = (subscription) => {


  setSelectedSubscription(subscription)
      setShowSubscriptionModal(true);
      trackEvent(`${subscription?.name} Viewed`, {
        'User': userData?.email || 'Anonymous',
      
      });
    
    };
  
    const handleCloseSubscriptionModal = () => {
      setShowSubscriptionModal(false);
    };
  
   
    const handleSubscribe = (subscription) => {
      handleCloseWishlist();
      navigate('/subscription-summary', { state: { subscription } });
    };

  return (
    <>
    <Offcanvas size='lg' show={showWishlist}  placement="end" className='py-4 px-1 px-md-3'style={{backgroundColor:'#FCFCFC'}} onHide={handleCloseWishlist}>
      <div className='d-flex gap-3 align-items-center px-2 px-md-0'> <img src={arrowLeft} onClick={handleCloseWishlist}/>
       <h5 className='my-auto'style={{color:'#0D111B', fontWeight:'600'}}>Wishlist</h5>
      </div>
      <Offcanvas.Body>
      {((!isLoggedIn && wishlistItems.length === 0) || (isLoggedIn && apiWishlistItems.length === 0)) && (
  <p>Your wishlist is empty.</p>
)}

      {!isLoggedIn && wishlistItems?.length > 0 && (
          <>
          <div>
            {wishlistItems.slice(0, 10).map((item, index) => (
              <Card key={`${item?.id}-${index}`}  style={{border:'none'}} className='mb-4'>
                 <div className='d-flex gap-3 align-items-center py-2' style={{borderBottom:'1px solid #fcfcfc'}}>
<Card className='border-0 ps-3' >


                  <Card.Img src={item?.product?.productImage || item?.box?.boxImage} style={{width:'80px', height:'80px', borderRadius:'20px'}}className=''/>
                  {item.box &&
                  <Card.ImgOverlay style={{}} className='d-flex justify-content-center align-items-center mt-3 text-center' > 
<p  style={{color:'#469B12', backgroundColor:'#ffffff', border:'1px solid #469B12', borderRadius:'8px', fontWeight:'500', fontSize:'7px', width:'100%', cursor:'pointer'}} onClick={()=>handleShowBoxModal(item?.box)} className='px-1 justify-content-center text-center align-items-center'><img src={arrow} alt='arrow'/> Look inside</p>
 </Card.ImgOverlay>}
 </Card>
                  <div className='me-3 mt-3'>
                    <p className='mb-0 pb-1' style={{fontWeight:'500', color:'#344054'}}>{item?.product?.productName || item?.box?.name}</p>
                  <div className='m-0 p-0 d-flex gap-2 align-items-center '>
            <p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'500'}} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.product?.price || item?.box?.amount)}</p>

            
             

         </div>
                  </div>
                </div>
                 <div className='px-3 py-2 d-flex justify-content-between align-items-center'>
              
                  <div className='d-flex gap-1 align-items-center'   onClick={() => {
    if (!cartItems.some(cartItem => cartItem?.id === (item?.product?.id || item?.box?.id))) {
      handleAddtoCart((item?.product?.id || item?.box?.id), 1, true);
    }
  }}  style={{
                    color: cartItems.some(cartItem => cartItem?.id === (item?.product?.id || item?.box?.id)) ? '#469B12' : '#000000', cursor:'pointer'
                }}>  
                  <img 
                    src={cartItems.some(cartItem => cartItem?.id === (item?.product?.id || item?.box?.id)) ? greenBag : bag} 
                    alt='cart action'
                  
                />
                  <p className='my-auto'>
                    {cartItems.some(cartItem => cartItem?.id === (item?.product?.id || item?.box?.id)) ? 'Added to Cart' : 'Add to Cart'}
                </p>
                   </div> 

                   {item?.box && 
    <Button className="d-flex text-center justify-content-center align-items-center px-2" style={{ height: '20px', fontSize:'14px'  }} onClick={() => handleSubscribe(item?.box)}>
    SUBSCRIBE
  </Button>
}
 
                   
                  <div className='d-flex gap-1 align-items-center pe-4'   onClick={() => handleDeleteFromWishlist(item?.product?.id || item?.box?.id)}>   <img src={redTrash} alt='trash'/><p  style={{color:'#CE371C', cursor:'pointer'}}className='my-auto'> Delete</p></div>
                 
                
                 </div>
                
              </Card>
            ))}

          </div>
{wishlistItems?.length > 10 && (
   <div className='my-5 pt-3 '>
   { showAuthButtons && !isLoggedIn ? (
     <div className='d-flex gap-3 '>
        <Button variant='outline-secondary p-md-3 w-100' style={{backgroundColor:"#ffffff",color:'#000000',fontWeight:'500', borderRadius:'8px', border:'none', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'

}}  onClick={() => { handleCloseWishlist();navigate('/login');  trackEvent('Sign in Click', {
 clicked_at: new Date().toISOString(),
 location_clicked: transformPath(window.location.pathname),
 button_clicked: 'Sign in'
});}}>Sign in</Button>
   <Button style={{borderRadius:'8px', border:'none', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'
}} className='p-md-3 w-100' onClick={() => {handleCloseWishlist(); navigate('/signup');  trackEvent('Create an Account Click', {
 clicked_at: new Date().toISOString(),
 location_clicked: transformPath(window.location.pathname),
 button_clicked: 'Create an account'
});}}>Create Account</Button>
     </div>
   ):  
  <div className='d-flex justify-content-center text-center'>
   <Button style={{borderRadius:'8px', border:'none', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'
 }} className='p-md-3 w-50'  onClick={handleViewAll}>View All</Button>
 </div>
}
 
 </div>
)}
         
         
          </>
        )}
        

         {isLoggedIn && apiWishlistItems?.length > 0 && (
          <div>
            {apiWishlistItems?.slice(0, 10).map((item) => (
              <Card key={item?.id} style={{border:'none'}} className='mb-4'>
                <div className='d-flex gap-3 align-items-center py-2' style={{borderBottom:'1px solid #fcfcfc'}}>
<Card className='border-0 ps-3' >


                  <Card.Img src={item?.product?.productImage || item?.box?.boxImage} style={{width:'80px', height:'80px', borderRadius:'20px'}}className=''/>
                  {item.box &&
                  <Card.ImgOverlay style={{}} className='d-flex justify-content-end mt-3' > 
<p  style={{color:'#469B12', backgroundColor:'#ffffff', border:'1px solid #469B12', borderRadius:'8px', fontWeight:'500', fontSize:'7px', width:'100%', cursor:'pointer'}} onClick={()=>handleShowBoxModal(item?.box)} className='px-1 justify-content-center text-center align-items-center'><img src={arrow} alt='arrow'/> Look inside</p>
 </Card.ImgOverlay>}
 </Card>
                  <div className='me-3 mt-3'>
                    <p className='mb-0 pb-1' style={{fontWeight:'500', color:'#344054'}}>{item?.product?.productName || item?.box?.name}</p>
                  <div className='m-0 p-0 d-flex gap-2 align-items-center '>
            <p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'500'}} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.product?.price || item?.box?.amount)}</p>

            
             

         </div>
                  </div>
                </div>
                 <div className='px-3 py-2 d-flex justify-content-between align-items-center'>
               
                  <div className='d-flex gap-1 align-items-center'   onClick={() => {
    if (!cartItems.some(cartItem => cartItem?.id === (item?.product?.id || item?.box?.id))) {
      handleAddtoCart((item?.product?.id || item?.box?.id), 1, true);
    }
  }}  style={{
                    color: cartItems.some(cartItem => cartItem?.id === (item?.product?.id || item?.box?.id)) ? '#469B12' : '#000000', cursor:"pointer"
                }}>  
                  <img 
                    src={cartItems.some(cartItem => cartItem?.id === (item?.product?.id || item?.box?.id)) ? greenBag : bag} 
                    alt='cart action'
                  
                />
                  <p className='my-auto'>
                    {cartItems.some(cartItem => cartItem?.id === (item?.product?.id || item?.box?.id)) ? 'Added to Cart' : 'Add to Cart'}
                </p>
                   </div> 
                   {item?.box && (
  userBoxes?.find(p => p?.boxes?.box?.id === item?.box?.id && p?.status === 'active') ? (
    <Button className="d-flex text-center justify-content-center align-items-center px-2" style={{ height: '20px', fontSize: '14px' }}>
      SUBSCRIBED
    </Button>
  ) : (
    <Button className="d-flex text-center justify-content-center align-items-center px-2" style={{ height: '20px', fontSize: '14px' }} onClick={() => handleSubscribe(item?.box)}>
      SUBSCRIBE
    </Button>
  )
)}

                   
                  <div className='d-flex gap-1 align-items-center pe-4'   onClick={() => handleDeleteFromWishlist(item?.product?.id || item?.box?.id)}>   <img src={redTrash} alt='trash'/><p  style={{color:'#CE371C',cursor:'pointer'}}className='my-auto'> Delete</p></div>
                 
                
                 </div>
                
              </Card>
            ))}

<div className='my-5 pt-3 '>


{ apiWishlistItems.length > 10  && 
<div className='d-flex justify-content-center text-center'>
<Button style={{borderRadius:'8px', border:'none', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'
}} className='p-md-3 w-50'  onClick={handleViewAll}>View All</Button>
</div>
}


</div>

          </div>)}

  

         
         
         
      </Offcanvas.Body>
    </Offcanvas>
      <SubscriptionModal showModal={showSubscriptionModal} handleCloseModal={handleCloseSubscriptionModal} selectedSubscription={selectedSubscription}/>
      </>
  );
};

export default Wishlist;
