
import createAxios from './httpCommon';
import Error from './error';
import { useNavigate } from 'react-router-dom';

import { localStorageGetItem, localStorageSetItem } from '../App';
 



export default () => {
  const { error } = Error();
  const navigate = useNavigate();
  
 
  const getAllProductCategory =  (
    setLoading, showToast, setAllCategories 
   
  ) => {
 
   
    setLoading(true);
    createAxios().then((axios) => {
      axios
        .get('/product/category')
        .then(async (response) => {
          
          
          
            const gotResponse =  response?.data?.categories;
           


 
 const allCategories = ['All', 'Trending', ...gotResponse];
 
 setAllCategories(allCategories)
 setTimeout(() => {
             
  setLoading(false);
}, 1000); 
    
        })
        .catch((e) => {
          // setLoading(false);
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        });
        
    });
  };
  const getProductCategory =  (
    setLoading, showToast, setCategories, setAllCategories = null
   
  ) => {
 
   
    setLoading(true);
    createAxios().then((axios) => {
      axios
        .get('/product/category')
        .then(async (response) => {
           
          
            const gotResponse =  response?.data?.categories?.categories;
          

 setCategories(gotResponse); 
 const allCategories = [
  { categoryName: 'All' }, 
  { categoryName: 'Trending' }, 
  ...gotResponse
];
if (setAllCategories){
  setAllCategories(allCategories);
}
setTimeout(() => {
             
  setLoading(false);
}, 1000); 
 
    
        })
        .catch((e) => {
          // setLoading(false);
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        });
        
    });
  };


  const filterProductsByCategory = (categoryId, setFilteredCategories, showToast) => {
   
  
    createAxios().then((axios) => {
      axios.get(`/product?categoryId=${categoryId}`)
      .then((response) => {
     
    
        setFilteredCategories(response?.data?.data?.products); // Adjust based on your response structure
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, showToast, retriesDone);
      });
    });
  };
  
  // Assuming the existence of necessary hooks and context for setLoading, showToast, etc.
  const filterByCategory = (categoryId,setLoading, setProducts, showToast) => {
    setLoading(true)
  
    createAxios().then((axios) => {
      axios.get(`/product?categoryId=${categoryId}`)
      .then((response) => {
       
     
        setProducts(response.data.data); // Adjust based on your response structure
        setTimeout(() => {
             
          setLoading(false);
      }, 1000); 
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, showToast, retriesDone);
      });
    });
  };

  const getGlobalProducts = (
    setLoading,
    showToast,
    setGlobalProducts,
    searchProduct = '',
  ) => {
  
    setLoading(true);
    createAxios().then((axios) => {
      let url = '/product';
      // Append search term to URL if provided
      if (searchProduct) {
        url += `?search=${searchProduct}`;
      }
      axios.get(url)
        .then((response) => {
          setLoading(false);
          if(searchProduct){
            const initializedProducts = response?.data?.data?.products.map(product => ({
              ...product,
              quantity: 0, 
            }));
    
          
              setGlobalProducts(initializedProducts);
          }else{
            const initializedProducts = response?.data?.data?.products?.map(product => ({
              ...product,
              quantity: 0, 
            }));
             
    
          
              setGlobalProducts(initializedProducts);
          }
       
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };




  const getTrendingProducts = (
   
    showToast,
    setTrendingProducts,
    setFilteredCategories = null
  ) => {
  
    createAxios().then((axios) => {
      axios.get('/product')
        .then((response) => {
        
          const initializedProducts = response?.data?.data?.trendingProducts?.map(product => ({
            ...product,
            quantity: 0, 
          }));
           
        
            setTrendingProducts(initializedProducts);
            if(setFilteredCategories){
              setFilteredCategories(initializedProducts);
            }
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, showToast, retriesDone); 
        });
    });
  };


  const getProducts = (
    setLoading,
    showToast,
    setProducts,
    setTrendingProducts = null,
    pagination,
    setPagination,
    searchProduct = '',
    
    isMobile = false // Added isMobile parameter
  ) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
    // Adjust pagination if it exists
    if (pagination) {
      currentPage = pagination.currentPage;
      itemsPerPage = pagination.itemsPerPage;
    }
  
    setLoading(true);
    createAxios().then((axios) => {
      let url = `/product?page=${currentPage}&size=${itemsPerPage}`;
      // Append search term to URL if provided
      if (searchProduct) {
        url += `&search=${searchProduct}`;
      }
  
      axios.get(url).then((response) => {
      
   
          const { products, pagination: newPagination , trendingProducts} = response.data.data;
          const initializedProducts = products.map(product => ({
            ...product,
            quantity: 0, // Initialize quantity to 0
          }));
        
          if (isMobile) {
            // On mobile, concatenate new products with existing ones
            setProducts(prevProducts => {
              // Filter out duplicates based on unique identifier (e.g., product ID)
              const uniqueProducts = initializedProducts.filter(newProduct =>
                !prevProducts.some(existingProduct => existingProduct?.id === newProduct?.id)
              );
              return [...prevProducts, ...uniqueProducts];
            });
          } else {
            // On larger screens, replace products
            setProducts(initializedProducts);
            
          }
  if(setTrendingProducts){
    const initializedTrendingProducts = trendingProducts.map(product => ({
      ...product,
      quantity: 0, // Initialize quantity to 0
    }));

    setTrendingProducts(initializedTrendingProducts)
  }
          // Update pagination state
          if (setPagination) {
            setPagination({
              currentPage: newPagination.currentPage,
              itemsPerPage,
              totalPages: newPagination.totalPages,
              totalProducts: newPagination.totalProducts,
            });
          }

          setTimeout(() => {
             
            setLoading(false);
        }, 1000); 
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const getMobileProducts = (
    setLoading,
    showToast,
    products,
    setProducts,
    setTrendingProducts = null,
    pagination,
    setPagination,
    searchProduct = '',
   
  ) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
    // Adjust pagination if it exists
    if (pagination) {
      currentPage = pagination.currentPage;
      itemsPerPage = pagination.itemsPerPage;
    }
  
    setLoading(true);
    createAxios().then((axios) => {
      let url = `/product?page=${currentPage}&size=${itemsPerPage}`;
      // Append search term to URL if provided
      if (searchProduct) {
        url += `&search=${searchProduct}`;
      }
  
      axios.get(url)
        .then((response) => {
       
       
        
          const initializedProducts = response?.data?.data?.products.map(product => ({
            ...product,
            quantity: 0, // Initialize quantity to 0
          }));
         
          if(setTrendingProducts){
            const initializedTrendingProducts =  response?.data?.data?.trendingProducts.map(product => ({
              ...product,
              quantity: 0, // Initialize quantity to 0
            }));
            setTrendingProducts(initializedTrendingProducts)
          }
        
            setProducts(prevProducts => {
            
              const uniqueProducts = initializedProducts.filter(newProduct =>
                !prevProducts.some(existingProduct => existingProduct?.id === newProduct?.id)
              );
              return [...prevProducts, ...uniqueProducts];
            });
         
          if (setPagination) {
            setPagination({
              currentPage: response?.data?.data?.pagination?.currentPage,
              itemsPerPage,
              totalPages: response?.data?.data?.pagination?.totalPages,
              totalProducts: response?.data?.data?.pagination?.totalProducts,
            });
          }

          setTimeout(() => {
             
            setLoading(false);
        }, 1000); 
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
 
  // const getProducts = (
  //   setLoading,
  //   showToast,
  //   setProducts,
  //   pagination,
  //   setPagination,
  //   searchProduct = '',
  //   isMobile = false // Added isMobile parameter
  // ) => {
  //   let currentPage = 1;
  //   let itemsPerPage = 20;
  
  //   // Adjust pagination if it exists
  //   if (pagination) {
  //     currentPage = pagination.currentPage;
  //     itemsPerPage = pagination.itemsPerPage;
  //   }
  
  //   setLoading(true);
  //   createAxios().then((axios) => {
  //     let url = `/product?page=${currentPage}&size=${itemsPerPage}`;
  //     // Append search term to URL if provided
  //     if (searchProduct) {
  //       url += `&search=${searchProduct}`;
  //     }
  
  //     axios.get(url).then((response) => {
   
  //         const { products, pagination: newPagination } = response.data.data;
  //         const initializedProducts = products.map(product => ({
  //           ...product,
  //           quantity: 0, // Initialize quantity to 0
  //         }));
  
  //         if (isMobile) {
  //           // On mobile, concatenate new products with existing ones
  //           setProducts(prevProducts => {
  //             // Filter out duplicates based on unique identifier (e.g., product ID)
  //             const uniqueProducts = initializedProducts.filter(newProduct =>
  //               !prevProducts.some(existingProduct => existingProduct?.id === newProduct?.id)
  //             );
  //             return [...prevProducts, ...uniqueProducts];
  //           });
  //         } else {
  //           // On larger screens, replace products
  //           setProducts(initializedProducts);
  //         }
  
  //         // Update pagination state
  //         if (setPagination) {
  //           setPagination({
  //             currentPage: newPagination.currentPage,
  //             itemsPerPage,
  //             totalPages: newPagination.totalPages,
  //             totalProducts: newPagination.totalProducts,
  //           });
  //         }

  //         setTimeout(() => {
             
  //           setLoading(false);
  //       }, 1000); 
  //       }).catch((e) => {
  //         // setLoading(false);
  //          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
  //           error(e, setLoading, showToast, retriesDone); 
  //       });
  //   });
  // };

  // const getMobileProducts = (
  //   setLoading,
  //   showToast,
  //   products,
  //   setProducts,
  //   pagination,
  //   setPagination,
  //   searchProduct = '',
   
  // ) => {
  //   let currentPage = 1;
  //   let itemsPerPage = 20;
  
  //   // Adjust pagination if it exists
  //   if (pagination) {
  //     currentPage = pagination.currentPage;
  //     itemsPerPage = pagination.itemsPerPage;
  //   }
  
  //   setLoading(true);
  //   createAxios().then((axios) => {
  //     let url = `/product?page=${currentPage}&size=${itemsPerPage}`;
  //     // Append search term to URL if provided
  //     if (searchProduct) {
  //       url += `&search=${searchProduct}`;
  //     }
  
  //     axios.get(url)
  //       .then((response) => {
       
       
        
  //         const initializedProducts = response?.data?.data?.products.map(product => ({
  //           ...product,
  //           quantity: 0, // Initialize quantity to 0
  //         }));
  
        
  //           setProducts(prevProducts => {
            
  //             const uniqueProducts = initializedProducts.filter(newProduct =>
  //               !prevProducts.some(existingProduct => existingProduct?.id === newProduct?.id)
  //             );
  //             return [...prevProducts, ...uniqueProducts];
  //           });
         
  //         if (setPagination) {
  //           setPagination({
  //             currentPage: response?.data?.data?.pagination?.currentPage,
  //             itemsPerPage,
  //             totalPages: response?.data?.data?.pagination?.totalPages,
  //             totalProducts: response?.data?.data?.pagination?.totalProducts,
  //           });
  //         }

  //         setTimeout(() => {
             
  //           setLoading(false);
  //       }, 1000); 
  //       }).catch((e) => {
  //         // setLoading(false);
  //          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
  //           error(e, setLoading, showToast, retriesDone); 
  //       });
  //   });
  // };
 



  const getMoreProducts = (
    setLoadingMore,
    showToast,
    setProducts,
    pagination = null,
    setPagination = null,
   
   
  ) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
    if (pagination) {
      ({ currentPage, itemsPerPage } = pagination);
    }
 
      setLoadingMore(true); 
    createAxios().then((axios) => {
      axios.get(`/product?page=${currentPage}&size=${itemsPerPage}`)
        .then((response) => {
        
           
       
          const { products, pagination } = response.data.data; // Updated to match your API response
          const initializedProducts = products.map(product => ({
            ...product,
            quantity: 0
          }));
          setProducts(prevProducts => {
                // Combine previous products with new ones
                const combinedProducts = [...prevProducts, ...initializedProducts];
                
                return combinedProducts.reduce((acc, current) => {
                  const x = acc.find(item => item.id === current.id);
                  if (!x) {
                    return acc.concat([current]);
                  } else {
                    return acc;
                  }
                }, []);
              });
         
  
          if (setPagination) {
            setPagination({ // Updated to directly use the pagination from the API
              currentPage: pagination.currentPage,
              itemsPerPage, // Assuming itemsPerPage is the same as requested
              totalPages: pagination.totalPages,
              totalProducts: pagination.totalProducts // Optionally store totalProducts if needed
            });
          }
          setTimeout(() => {
             
            setLoadingMore(false);
        }, 1000); 
        })
        .catch((e) => {
          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, showToast, retriesDone);
        });
    });
  };

  const getProductswithSearch = (query,setSearchLoading,showToast,setProducts, setSearchResults, setSearchPerformed, pagination, setPagination) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
  
    if (pagination) {
      currentPage = pagination.currentPage;
      itemsPerPage = pagination.itemsPerPage;
    }
  
    setSearchLoading(true);
    createAxios().then((axios) => {
      axios.get(`/product?page=${currentPage}&size=${itemsPerPage}&search=${query}`)
        .then((response) => {
       

          const { products, pagination: newPagination, resultMatches } = response.data.data;
          const initializedProducts = products.map(product => ({
            ...product,
            quantity: 0, // Initialize quantity to 0
          }));
          setSearchPerformed(true);
          setSearchResults(resultMatches)
  
            setProducts(initializedProducts);
        
  
          // Update pagination state
          if (setPagination) {
            setPagination({
              currentPage: newPagination.currentPage,
              itemsPerPage,
              totalPages: newPagination.totalPages,
              totalProducts: newPagination.totalProducts,
            });
          }
          setTimeout(() => {
             
            setSearchLoading(false);
        }, 1000); 

        }).catch((e) => {
          setSearchLoading(false);
          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, showToast, retriesDone);
        });
    });
  };
  
  const getSingleProduct = (
    productId,
    setLoading,
    showToast,
   setSelectedProduct
  ) => {
   
    setLoading(true);
    createAxios().then((axios) => {
      axios.get(`/product/${productId}`)
        .then((response) => {
         
       
          const product = response?.data?.data;
          if (product) {
            product.quantity = product.quantity || 0;
            setSelectedProduct(product);
          }
           
          setTimeout(() => {
             
            setLoading(false);
        }, 1000);
  
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const getAllBoxes = (
    setLoading, showToast, setSubscriptionBoxes, 
 
  ) => {
  
    setLoading(true);
    createAxios().then((axios) => {
      axios.get('/subscription/box')
        .then((response) => {
        
          const initializedBoxes = response?.data?.data?.map(box => ({
            ...box,
            quantity: 0, 
          }));
        
            setSubscriptionBoxes(initializedBoxes);
            setTimeout(() => {
             
              setLoading(false);
          }, 1000); 
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const getUserBoxes = (
    setLoading, showToast, setUserBoxes, 
 
  ) => {
    const token = localStorageGetItem
('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.get('/subscription/me',  {
        headers: {
          'X-Auth-Token': token,
        },
      })
        .then((response) => {
        
      
            setUserBoxes(response?.data?.data);
            setTimeout(() => {
             
              setLoading(false);
          }, 1000); 
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const getSingleBoxes = (
    boxId,
    setSubscription,
    setLoading,
    showToast
  ) => {
   
    setLoading(true);
    createAxios().then((axios) => {
      axios.get(`/subscription/box/${boxId}`)
        .then((response) => {
          setSubscription(response?.data?.data);
      setTimeout(() => {
             
            setLoading(false);
        }, 1000);
  
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };


  const getVouchers = (
    setLoading, showToast, setVouchers, 
 
  ) => {
  
    setLoading(true);
    createAxios().then((axios) => {
      axios.get('/vouchers')
        .then((response) => {
        
        
            setVouchers(response?.data);
            setTimeout(() => {
             
              setLoading(false);
          }, 1000); 
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const getSingleVoucher = (
    voucherId,
    setVoucher,
    setLoading,
    showToast
  ) => {
   
    setLoading(true);
    createAxios().then((axios) => {
      axios.get(`/vouchers/${voucherId}`)
        .then((response) => {
          setVoucher(response?.data);
      setTimeout(() => {
             
            setLoading(false);
        }, 1000);
  
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
  
  return {
   
getProducts, getProductCategory, getAllProductCategory,getMoreProducts, filterProductsByCategory, getProductswithSearch, getGlobalProducts, getSingleProduct, filterByCategory, getMobileProducts, getTrendingProducts, getSingleBoxes,getUserBoxes,getAllBoxes, getVouchers, getSingleBoxes, getSingleVoucher
    };
};