
const fetchIPAndCountry = async () => {
    try {
      const response = await fetch('https://ipinfo.io/json?token=b806926d439ffc');
      const data = await response.json();
      // console.log(data, 'ip address');
      return {
        ip: data.ip,
        country: data.country,
        region: data.region
      };
    } catch (error) {
      console.error('Error fetching IP and country:', error);
      return {
        ip: '',
        country: '',
        region: ''
      };
    }
  };

  const generateEventID = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 1000000)}`;
  };

 
  export const trackPageView = async (location, userData) => {
    const eventId = generateEventID();
    const customerInfo = await fetchIPAndCountry();
    const pageName = location.pathname === '/' ? 'Home Page' : `${location.pathname.substring(1)} Page`;
    // Facebook Pixel tracking
    // window.fbq('trackCustom', pageName, {
    //   event_id: eventId,
    //   client_ip_address: customerInfo.ip,
    //   client_user_agent: navigator.userAgent,
    //   client_country: customerInfo.country,
    //   client_region: customerInfo.region,
    //   client_email: customerInfo.email
    // });
  
    // Google Analytics tracking
    window.gtag('event', 'page_view', {
      event_category: 'Page Views',
      event_label: pageName,
      event_id: eventId,
      page_location: window.location.href,
      client_ip_address: customerInfo.ip,
      client_user_agent: navigator.userAgent,
      client_country: customerInfo.country,
      client_region: customerInfo.region,
      client_name:  userData ? `${userData?.firstName} ${userData?.lastName}` : 'Guest',
      client_email: userData ? userData?.email : 'Guest'
    });
  };
  export const trackViewContent = async (contentType, userData) => {
    const eventId = generateEventID();
    const customerInfo = await fetchIPAndCountry();
  
    // Facebook Pixel tracking
    // window.fbq('track', 'ViewContent', {
    //   content_ids: [contentId],
    //   content_type: contentType,
    //   event_id: eventId,
    //   client_ip_address: customerInfo.ip,
    //   client_user_agent: navigator.userAgent,
    //   client_country: customerInfo.country,
    //   client_region: customerInfo.region,
    //   client_email: customerInfo.email
    // });
  
    // Google Analytics tracking
    window.gtag('event', contentType, {
      event_category: 'View Content',
      event_label: contentType,
      
      event_id: eventId,
      client_ip_address: customerInfo.ip,
      client_user_agent: navigator.userAgent,
      client_country: customerInfo.country,
      client_region: customerInfo.region,
         client_name:  userData ? `${userData?.firstName} ${userData?.lastName}` : 'Guest',
      client_email: userData ? userData?.email : 'Guest'
    });
  };
  
  export const trackAddToCart = async (productId, productName, productPrice, eventType, userData) => {
    const eventId = generateEventID();
    const customerInfo = await fetchIPAndCountry();
  
    // Facebook Pixel tracking
    // window.fbq('track', 'AddToCart', {
    //   content_ids: [productId],
    //   content_type: productName,
    //   value: productPrice,
    //   event_id: eventId,
    //   client_ip_address: customerInfo.ip,
    //   client_user_agent: navigator.userAgent,
    //   client_country: customerInfo.country,
    //   client_region: customerInfo.region,
    //      client_name:  userData ? `${userData?.firstName} ${userData?.lastName}` : 'Guest',
      //client_email: userData ? userData?.email : 'Guest'
    // });
  
    // Google Analytics tracking
    window.gtag('event', eventType, {
      event_category: eventType,
      event_label: productName,
      items: [{
        id: productId,
        name: productName,
        price: productPrice
      }],
      event_id: eventId,
      value: productPrice,
      client_ip_address: customerInfo.ip,
      client_user_agent: navigator.userAgent,
      client_country: customerInfo.country,
      client_region: customerInfo.region,
         client_name:  userData ? `${userData?.firstName} ${userData?.lastName}` : 'Guest',
      client_email: userData ? userData?.email : 'Guest'
    });
  };
  
  export const trackAddToWishlist = async (productId, productName,eventType, userData) => {
    const eventId = generateEventID();
    const customerInfo = await fetchIPAndCountry();
  
    // Facebook Pixel tracking
    // window.fbq('track', 'AddToWishlist', {
    //   content_ids: [productId],
    //   content_type: productName,
    //   event_id: eventId,
    //   client_ip_address: customerInfo.ip,
    //   client_user_agent: navigator.userAgent,
    //   client_country: customerInfo.country,
    //   client_region: customerInfo.region,
    //      client_name:  userData ? `${userData?.firstName} ${userData?.lastName}` : 'Guest',
      //client_email: userData ? userData?.email : 'Guest'
    // });
  
    // Google Analytics tracking
    window.gtag('event', eventType, {
      event_category: eventType,
      event_label: productName,
      items: [{
        id: productId,
        name: productName
      }],
      event_id: eventId,
      client_ip_address: customerInfo.ip,
      client_user_agent: navigator.userAgent,
      client_country: customerInfo.country,
      client_region: customerInfo.region,
      client_name:  userData ? `${userData?.firstName} ${userData?.lastName}` : 'Guest',
      client_email: userData ? userData?.email : 'Guest'
    });
  };
  
  export const trackCompleteRegistration = async (status, email, name, registerType) => {
    const eventId = generateEventID();
    const customerInfo = await fetchIPAndCountry();
  
    // Facebook Pixel tracking
    // window.fbq('track', 'CompleteRegistration', {
    //   event_id: eventId,
    //   status: status, // 'success' or 'failure'
    //   client_ip_address: customerInfo.ip,
    //   client_user_agent: navigator.userAgent,
    //   client_country: customerInfo.country,
    //   client_region: customerInfo.region,
    //   client_email: email || customerInfo.email
    // });
  
    // Google Analytics tracking
    window.gtag('event', `${registerType}`, {
      event_category: registerType,
      event_label: status,
      method: 'email',
      event_id: eventId,
     
      client_ip_address: customerInfo.ip,
      client_user_agent: navigator.userAgent,
      client_country: customerInfo.country,
      client_region: customerInfo.region,
      client_name: name || 'Guest',
      client_email: email  || 'Guest'
    });
  };
  
  export const trackInitiateCheckout = async (status, eventType, itemCount, contents, userData) => {
    const eventId = generateEventID();
    const customerInfo = await fetchIPAndCountry();
  
    // Facebook Pixel tracking
    // window.fbq('track', 'InitiateCheckout', {
    //   status: status,
    //   num_items: itemCount,
    //   contents: contents,
    //   event_id: eventId,
    //   client_ip_address: customerInfo.ip,
    //   client_user_agent: navigator.userAgent,
    //   client_country: customerInfo.country,
    //   client_region: customerInfo.region,
    //      client_name:  userData ? `${userData?.firstName} ${userData?.lastName}` : 'Guest',
     // client_email: userData ? userData?.email : 'Guest'
    // });
  
    // Google Analytics tracking
    window.gtag('event', eventType, {
      event_category: eventType,
      event_label: status,
      items: contents,
      event_id: eventId,
      num_items: itemCount,
      client_ip_address: customerInfo.ip,
      client_user_agent: navigator.userAgent,
      client_country: customerInfo.country,
      client_region: customerInfo.region,
     client_name:  userData ? `${userData?.firstName} ${userData?.lastName}` : 'Guest',
      client_email: userData ? userData?.email : 'Guest'
    });
  };
  
  
  