import { useState, useRef, useEffect } from 'react'
import logo from '../../assets/virtue-logo-2.png'
import { Navbar,Nav, Form, Button, Image, Overlay, Popover, Row,Col, CloseButton} from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import search from '../../assets/search.svg'
import love from '../../assets/love.svg'
import basket from '../../assets/shopping-cart.svg'
import arrowDown from '../../assets/arrow-down.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from '../../AppContext'
import { trackEvent } from '../../mixpanel'
import caretDown from '../../assets/caret-down.svg'
import caretDownWhite from '../../assets/caret-down-white.svg'
import logo2 from '../../assets/footer-logo.png'
import orderRoute from '../../services/orderRoute'
import logout from '../../assets/logout.svg'
import profile from '../../assets/profile.svg'
import productRoute from '../../services/productRoute'
import Select from 'react-select';
import userRoute from "../../services/userRoute";
import Waitlist from '../Modal/Waitlist'
import useGoogleLoader from '../../hooks/useGoogleLoader'
import { localStorageGetItem, localStorageRemoveItem, localStorageSetItem, transformPath } from '../../App';
import { trackViewContent } from '../../services/tracking'





export function getParsedLocalStorageItem(key) {
  const item = localStorageGetItem
(key);
  if (item !== null) {
    try {
      return JSON.parse(item);
    } catch (e) {
      console.error(`Parsing error for key "${key}":`, e);
      // Optionally handle the error, e.g., by clearing the invalid item
      localStorageRemoveItem(key);
    }
  }
  return null; // or a suitable default value for when the key is missing or parsing fails
}



const GuestNavbar =  () => {
  const { cartItems,handleShowCart, handleShowWishlist,wishlistItems, showToast, setShowLogoutModal, setSearchLoading, searchQuery, setSearchQuery, setProducts, setSearchResults, pagination, setPagination, setSearchPerformed, recentSearches, setRecentSearches, formData,setFormData, isMobile}= useAppContext()
  const navigate = useNavigate()
  const location = useLocation();
  
  const {getDeliveryStates, getDeliveryLGAs}= orderRoute();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [deliveryStates, setDeliveryStates] = useState([]);
  const [lgaOptions, setLgaOptions] = useState([]);
  const [originalLgaOptions, setOriginalLgaOptions] = useState([]);

  const [isDeliveryPopoverVisible, setIsDeliveryPopoverVisible] = useState(false);
  const [isUnauthenticatedPopoverVisible, setIsUnauthenticatedPopoverVisible] = useState(false);
const {getProductswithSearch}=productRoute();
  const [expanded, setExpanded] = useState(false);
  const popoverRef = useRef(null);
  const targetRef = useRef(null);
  const deliveryRef = useRef(null) 
  const unauthenticatedRef = useRef(null) 
  
const [isSuccessful, setIsSuccessful] = useState(true)
  const calculateTotalItems = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };
  const totalItems = calculateTotalItems()
  const userData = getParsedLocalStorageItem('userData');
 

    const token = localStorageGetItem
('userToken');
    const isLoggedIn = !!userData && !!token;
   const profileImage = userData?.profilePicture ;
   const fullName = `${userData?.firstName} ${userData?.lastName}`;
   const displayName = fullName.length > 22 ? userData.firstName : fullName;

   const [isFocused, setIsFocused] = useState(false);
   const totalWishlistItems = localStorageGetItem
('totalWishlistItems') || 0;
const searchTimeout = useRef(null); // Timeout for debouncing the search
const recentSearchTimeout = useRef(null); // Timeout for updating recent searches
  //  const [searchTimeout, setSearchTimeout] = useState(null);
   const [showWaitlist, setShowWaitlist] = useState(false);
  
  ;
  const googleLoader = useGoogleLoader();
  const streetAddressAutoCompleteInput = useRef(null);
 



  const handleSelect = (eventKey, event) => {
    setExpanded(false); 
    
  };
  
  
 
  
  const toggleNavbar = () => {
    setExpanded(prev => !prev);
    if (isPopoverVisible) {
      hidePopover();
    }
    if (isDeliveryPopoverVisible) {
      hideDeliveryPopover();
    }

    if (isUnauthenticatedPopoverVisible) {
      hideUnauthenticatedPopover();
    }
 
    if (expanded) {
      setIsPopoverVisible(false);
      setIsDeliveryPopoverVisible(false)
      setIsUnauthenticatedPopoverVisible(false)
    }
  };
  const togglePopover = () => {
    setIsPopoverVisible(prev => !prev);
  };

  const toggleUnauthenticatedPopover = () => {
    setIsUnauthenticatedPopoverVisible(prev => !prev);
  };

  const toggleDeliveryPopover = () => {
    setIsDeliveryPopoverVisible(prev => !prev);
  };

  const hidePopover = () => setIsPopoverVisible(false);

 

  const hideDeliveryPopover = () => setIsDeliveryPopoverVisible(false);

  const hideUnauthenticatedPopover = () => setIsUnauthenticatedPopoverVisible(false);

 
  const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden';
  };
  
  const enableBodyScroll = () => {
    document.body.style.overflow = 'visible';
  };
  
  useEffect(() => {
    if ( isDeliveryPopoverVisible) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  
    return () => enableBodyScroll();  // Clean up function to re-enable scrolling
  }, [isDeliveryPopoverVisible]);
 
  
  const handleScroll = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      // If the scroll event target is not inside the popover, close the popover
      hideDeliveryPopover();
    } else {
      // Stop propagation if it's a scroll inside the popover to keep it open
      event.stopPropagation();
    }
  };
  
  useEffect(() => {
    // Only add event listeners if the delivery popover is visible
    if (isDeliveryPopoverVisible) {
      document.addEventListener('touchmove', handleScroll, { passive: false });
      document.addEventListener('scroll', handleScroll, { passive: false });
    }
  
    return () => {
      document.removeEventListener('touchmove', handleScroll);
      document.removeEventListener('scroll', handleScroll);
    };
  }, [isDeliveryPopoverVisible]);  // Dependency on the visibility of the delivery popover
  

  
  useEffect(() => {
  
    const handleScroll = () => {
      
      hidePopover();
      hideUnauthenticatedPopover();
    };

    if (isPopoverVisible || isUnauthenticatedPopoverVisible) {
    
      window.addEventListener('scroll', handleScroll);
    }

   
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isPopoverVisible, isUnauthenticatedPopoverVisible]);

 
  useEffect(() => {
 
    if (formData.state) {
      const selectedState = deliveryStates.find(state => state.name === formData.state);
      if (selectedState) {
        getDeliveryLGAs(selectedState.id, showToast, setLgaOptions, setOriginalLgaOptions);
      }
    }
  }, [formData.state, deliveryStates]);

  useEffect(()=>{
    getDeliveryStates(
      showToast, setDeliveryStates 
    )
  },[])

  useEffect(() => {
    let autocompleteStreetAddress;
  
    if (isDeliveryPopoverVisible) {
      const handlePlaceSelect = (autocomplete) => {
        const place = autocomplete.getPlace();
        const formattedAddress = place.formatted_address || "";
        const addressComponents = place.address_components;
  
        // Function to normalize state names
        const normalizeStateName = (stateName) => {
          const stateMap = {
            'Lagos': ['Lagos', 'Ìpínlẹ̀ Èkó', 'Lagos State'],
            'Ogun': ['Ogun', 'Ìpínlẹ̀ Ògùn', 'Ogun State']
          };
  
          for (const [key, values] of Object.entries(stateMap)) {
            if (values.includes(stateName)) {
              return key;
            }
          }
          return stateName;
        };
  
        // Check if the address belongs to the specified state
        const stateLongName = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name;
        const normalizedStateName = normalizeStateName(stateLongName);
      
        const isInSpecifiedState = formData.state === normalizedStateName;
  
        if (isInSpecifiedState) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            streetAddress: formattedAddress,
          }));
       
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            streetAddress: '',
          }));
          showToast({ toastText: `Selected place is not in ${formData.state} state.`, isSuccess: false });
          
    
        }
      };
  
      const setAutocompleteBounds = (google, autocomplete) => {
        // Define bounds for Lagos
        const lagosBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(6.3933, 3.1473),
          new google.maps.LatLng(6.7009, 3.4860)
        );
  
        // Define bounds for Ogun
        const ogunBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(6.3445, 2.5993),
          new google.maps.LatLng(7.2948, 3.7181)
        );
  
        if (formData.state === 'Lagos') {
          autocomplete.setBounds(lagosBounds);
        } else if (formData.state === 'Ogun') {
          autocomplete.setBounds(ogunBounds);
        } else {
          autocomplete.setBounds(null); // Clear bounds if state is not Lagos or Ogun
        }
      };
  
      googleLoader.load().then((google) => {
        const options = {
          componentRestrictions: { country: "NG" }, // Restrict search to Nigeria
        };
  
        if (streetAddressAutoCompleteInput.current) {
          autocompleteStreetAddress = new google.maps.places.Autocomplete(
            streetAddressAutoCompleteInput.current,
            options
          );
  
          autocompleteStreetAddress.addListener("place_changed", () => {
            handlePlaceSelect(autocompleteStreetAddress);
          });
  
          setAutocompleteBounds(google, autocompleteStreetAddress);
        }
      });
  
      return () => {
        if (autocompleteStreetAddress) {
          window.google.maps.event.clearInstanceListeners(autocompleteStreetAddress);
        }
      };
    }
  }, [googleLoader, isDeliveryPopoverVisible, formData.state, setFormData]);
  
  useEffect(() => {
   if (isDeliveryPopoverVisible){
    setFormData((prevFormData) => ({
      ...prevFormData,
      streetAddress: ''
    }));}
  }, [formData.state]);
  
  
  const handleFocus = () => {
    setIsFocused(true)
    if (location.pathname !== '/search') {
      navigate('/search');
    }
  };

  const handleBlur = () => {
    setIsFocused(true);
  };
  const MAX_RECENT_SEARCHES = 10; // For example, limit to 5 recent searches

  const updateRecentSearches = (query) => {
   
    const normalizedQuery = query.toLowerCase();
  
    const filteredSearches = recentSearches.filter(q => q.toLowerCase() !== normalizedQuery);
  
    const updatedSearches = [query, ...filteredSearches].slice(0, MAX_RECENT_SEARCHES);
  
    setRecentSearches(updatedSearches);
    localStorageSetItem
('recentSearches', JSON.stringify(updatedSearches));
  };
  

  const handleSearchChange = (event) => {
    setIsFocused(true);
  
    if (location.pathname !== '/search') {
      navigate('/search');
    }
    const searchQuery = event.target.value;
    setSearchQuery(searchQuery);
    const firstSpaceIndex = searchQuery.indexOf(' ');
    const firstParenthesisIndex = searchQuery.indexOf('(');
    
    let cutIndex = -1;
    if (firstSpaceIndex === -1 && firstParenthesisIndex === -1) {
      cutIndex = searchQuery.length; // No space or parenthesis found, use the whole string
    } else if (firstSpaceIndex === -1) {
      cutIndex = firstParenthesisIndex; // No space found, cut at the parenthesis
    } else if (firstParenthesisIndex === -1) {
      cutIndex = firstSpaceIndex; // No parenthesis found, cut at the space
    } else {
      // Both space and parenthesis found, cut at the earliest one
      cutIndex = Math.min(firstSpaceIndex, firstParenthesisIndex);
    }
  
    const query = searchQuery.substring(0, cutIndex);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    // Clear the previous recent search timeout before setting a new one
    if (recentSearchTimeout.current) {
      clearTimeout(recentSearchTimeout.current);
    }
    setProducts([]);
    setSearchResults([]);
    setSearchLoading(true);
    // Set a new timeout for the search function
    searchTimeout.current = setTimeout(() => {
      if (searchQuery.trim()) {
        getProductswithSearch(query, setSearchLoading, showToast, setProducts, setSearchResults, setSearchPerformed, pagination, setPagination);
      } else {
        setProducts([]);
        setSearchResults([]);
        setSearchPerformed(false);
        setSearchLoading(false);
      }
    }, 500); // 500ms debounce delay

    // Set a new timeout for updating recent searches after 3 seconds of inactivity
    recentSearchTimeout.current = setTimeout(() => {
      if (query) {
        updateRecentSearches(searchQuery);
      }
    }, 3000); 
    
    //   if (searchQuery.trim()) {
    //   // Instantly trigger search without waiting
    //   getProductswithSearch(query, setSearchLoading, showToast, setProducts, setSearchResults, setSearchPerformed, pagination, setPagination);
    // } else {
    //   setProducts([]);
    //   setSearchResults([]);
    //   setSearchPerformed(false);
    //   setSearchLoading(false);
    // }
  
    // // Clear the existing timeout every time the user types something
    // if (searchTimeout) clearTimeout(searchTimeout);
  
    // // Set a new timeout for updating recent searches
    // setSearchTimeout(setTimeout(() => {
    //   // Only update recent searches after 5 seconds of inactivity
    //   if (query) {
    //     updateRecentSearches(searchQuery);
    //   }
    // }, 3000)); // 5000 milliseconds = 5 seconds
  };
  

  


  const handleDeliveryAddressSubmit = (e) => {
    e.preventDefault();
   
    
      setIsDeliveryPopoverVisible(false); 
    
    
   
    
  };

  const handleLogout = () => {
 
hidePopover();


    setShowLogoutModal(true);
  };

  const formatOptionLabel = ({ label, value }) => {
    if (value === "comingSoon") {
      // Split the label to insert styling on a specific part
      const firstPart = "We are coming to ";
      const secondPart = label.split('soon! ')[0].replace(firstPart, '');
      const thirdPart = "soon! ";
      const actionPart = "Join our waitlist and be the first to know when we do.";
     
  
      return (
        <div>
          {firstPart}
          <span>{secondPart}</span>
          {thirdPart}
          <span style={{ textDecoration: "underline", color:'#469B12 ' }}>{actionPart}</span>
        
        </div>
      );
    }
    // Return the label as is for other options
    return label;
  };

  const handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change") {
      const filteredLgas = originalLgaOptions.filter(lga => lga.label.toLowerCase().includes(inputValue.toLowerCase()));
      if (filteredLgas.length === 0 && inputValue.trim() !== "") {
        setLgaOptions([{ value: "comingSoon", label: `We are coming to ${inputValue} soon! Join our waitlist and be the first to know when we do.` }]);
      } else {
        setLgaOptions(filteredLgas);
      }
    }
  };
  
  
  
  
  

  const handleSelectChange = (selectedOption) => {
    if (!selectedOption) {
      // User cleared the selection
      setFormData((prevFormData) => ({
        ...prevFormData,
        LGA: '' // Reset or adjust according to your needs
      }));
      setLgaOptions(originalLgaOptions)
    } else if (selectedOption.value === "comingSoon") {
      setIsSuccessful(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        LGA: ''
      }));
      setShowWaitlist(true); 
      const selectedState = deliveryStates.find(state => state.name === formData.state);
      if (selectedState) {
        getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
      }
      setIsDeliveryPopoverVisible(false); 
    
    } else {
      // User selected an option; handle normally
      setFormData((prevFormData) => ({
        ...prevFormData,
        LGA: selectedOption.value
      }));
    }
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
  
    // Update form data as usual
    setFormData({ ...formData, [name]: value });
  
    if (name === "state") {
      const selectedState = deliveryStates.find(state => state.name === value);
      if (selectedState) {
        getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
      }else{setLgaOptions([]); }
    }
  };
  
  

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className='gap-0 pb-1' onClick={(e) => e.stopPropagation()}>
        <div className=''>
          <h6 style={{ fontWeight: '600', color: '#2E2C34' }}>{userData?.firstName} {userData?.lastName}</h6>
          <p style={{ color: '#83879B' }}> {userData?.email}</p>
        </div>
        <hr style={{ margin: '0.5rem 0 0.2rem 0' }}/>
        <div className='d-flex flex-column gap-3 mb-3 mt-0'>
        <a href="/profile/personal-data" className="d-flex gap-3 mt-3 align-items-center">
        <img src={profile} alt='profile icon'/>
          <p className='my-auto'style={{ color: "#718096" }}>Profile</p>
        </a>
     
        <a href="/profile/orders" className="d-flex gap-3 m-0 py-0 align-items-center">
        <FontAwesomeIcon style={{ color: "#718096" }} icon={faBasketShopping}/>
          <p className='my-auto' style={{ color: "#718096" }}>Orders</p>
        </a>

        <div className='d-none d-sm-flex d-md-none gap-3 m-0 py-0 align-items-center'  onClick={() => {hidePopover(); handleShowCart(); trackViewContent('View Cart', userData && userData);}}>
    <i className='bi bi-cart2' style={{ color: "#718096" , fontSize:'1.1rem'}}></i>
      <p className='my-auto' style={{ color: "#718096" }}>Cart</p>
      </div>
      
      <div className='d-none d-sm-flex d-lg-none gap-3 m-0 py-0 align-items-center'  onClick={() => {hidePopover(); handleShowWishlist();trackViewContent('View Wishlist', userData && userData)}}>
    <i className='bi bi-heart' style={{ color: "#718096" , fontSize:'1.1rem'}}></i>
      <p className='my-auto' style={{ color: "#718096" }}>Wishlist</p>
      </div>
        <a className="d-flex gap-3 m-0 py-0 align-items-center" onClick={handleLogout} style={{cursor:'pointer'}}>
        <img src={logout} alt='logout icon'/>
          <p className='my-auto' style={{ color: "#718096" }}>Log out</p>
        </a>
        </div>
        <hr style={{ margin: '0.5rem 0' }}/>
        <div style={{borderTopLeftRadius:'52px', borderBottomLeftRadius:'52px', width:'',height:'', border:'1px solid #EDE9EA'}} className='py-1 ps-1 pe-2 mb-3'>
<div className="row " >

<div  className="col-12 ">
<div className="d-flex align-items-center gap-1 rounded-3 ">
 <div className="flex-shrink-0">
 <i className="bi bi-whatsapp p-2" style={{border:'1px solid #EDE9EA', borderRadius:'50%'}}></i>
 </div>
 <div className="flex-grow-1">
   <div className="">
   <a href='https://chat.whatsapp.com/LQMW9mgRWN2L3LlO8UXFAo' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}>
     <p className="mb-0 text-dark" style={{fontWeight:'600'}}>Chat/Shop on Whatsapp</p>
     <p className="text-truncate text-decoration-underline mb-0" style={{color:'#9CA3AF'}}>+234 816 664 1019 </p>
     </a>
   </div>
 </div>
</div>
</div>

</div>
</div>
      </Popover.Body>
    </Popover>
  );

  const unauthenticatedPopover = (
    <Popover id="popover-basic">
      <Popover.Body className='gap-0 pb-1'>
       
       
        <div className='d-flex flex-column gap-3 mb-2 mt-0'>
       

        <div className='d-none d-sm-flex d-md-none gap-3 m-0 py-0 align-items-center'  onClick={()=>{hideUnauthenticatedPopover(); handleShowCart();trackViewContent('View Cart', userData && userData)}}>
    <i className='bi bi-cart2' style={{ color: "#718096" , fontSize:'1.1rem'}}></i>
      <p className='my-auto' style={{ color: "#718096" }}>Cart</p>
      </div>
      
      <div className='d-none d-sm-flex d-lg-none gap-3 m-0 py-0 align-items-center'  onClick={()=> {hideUnauthenticatedPopover(); handleShowWishlist(); trackViewContent('View Wishlist', userData && userData)}}>
    <i className='bi bi-heart' style={{ color: "#718096" , fontSize:'1.1rem'}}></i>
      <p className='my-auto' style={{ color: "#718096" }}>Wishlist</p>
      </div>
        <a className="d-flex gap-3 m-0 py-0 align-items-center" onClick={() => {hideUnauthenticatedPopover(); navigate('/signup');  trackEvent('Create an Account Click', {
    clicked_at: new Date().toISOString(),
    location_clicked: transformPath(window.location.pathname),
    button_clicked: 'Create an account'
  });}} style={{cursor:'pointer'}}>
        <i className="bi bi-person-plus" style={{ color: "#718096", fontSize:'1.1rem' }}></i>
          <p className='my-auto' style={{ color: "#718096" }}>Create Account</p>
        </a>
        <a className="d-flex gap-3 m-0 py-0 align-items-center" onClick={() => {hideUnauthenticatedPopover(); navigate('/login');  trackEvent('Sign in Click', {
    clicked_at: new Date().toISOString(),
    location_clicked: transformPath(window.location.pathname),
    button_clicked: 'Sign in'
  });}} style={{cursor:'pointer'}}>
        <i className="bi bi-person-check" style={{ color: "#718096", fontSize:'1.1rem' }}></i>
          <p className='my-auto' style={{ color: "#718096" }}>Sign in</p>
        </a>
        </div>
    
     
      </Popover.Body>
    </Popover>
  );

  const deliveryPopover = isMobile ? (<div className={`modal ${isDeliveryPopoverVisible ? 'show' : ''}`} tabIndex="-1" style={{ display: isDeliveryPopoverVisible ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Enter your delivery address</h5>
        <CloseButton onClick={() => setIsDeliveryPopoverVisible(false)}/>
      </div>
      <div className="modal-body">
      <Form className='my-3' onSubmit={handleDeliveryAddressSubmit}>
        
      <Row>
              <Col xs={12} sm={5}>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D'}}>State/Province</p>
          
            <Form.Select style={{width: '100%'}}
        className="custom-select"
        onChange={handleChange}
        value={formData.state || ''}
        name="state"
        required
      > 
      <option value="" disabled>Select a state</option>
      {deliveryStates.map((state,index) => (
        <option value={state?.name} key={index}>{state?.name}</option>
    ))}

      </Form.Select>

            </Form.Group>
              </Col>
              <Col xs={12} sm={7}>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D'}}>Delivery Area {!formData?.LGA && <span>(Please select a state first)</span>}</p>
            
    <Select
      formatOptionLabel={formatOptionLabel}
      options={lgaOptions}
      onInputChange={handleInputChange}
      onChange={handleSelectChange}
      value={lgaOptions.find(option => option.value === formData.LGA) || ''}
      name="LGA"
      required
      isClearable={true}
      styles={{
        menuList: (provided) => ({
          ...provided,
          maxHeight: '120px', 
          overflowY: 'auto'    
        })
      }}
   
    />



            </Form.Group>
              </Col>
             </Row>
            <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D'}}>Street Address {!formData?.LGA && <span>(Please select a state first)</span>}</p>
            <div className="input-group position-relative">
  <Form.Control
    type="text"
    placeholder="e.g. 1 Virtue shop, Freshness Street, Lagos"
    style={{ border: '1px solid #D7D7D7', width: '100%' }}
    required
    onChange={handleChange}
    name="streetAddress"
    value={formData.streetAddress}
   
  />
  {formData.streetAddress && (
   <i className='bi bi-x'  onClick={() => setFormData({ ...formData, streetAddress: '' })}
   style={{ top: "2.5%", right:'1%', zIndex: 100, fontSize:'1.5rem', position:'absolute'}}></i>
  )}
</div>

            </Form.Group>

           

             <div className='text-center mb-3'>
              <Button type='submit'>Submit</Button>
             </div>
             </Form>
      </div>
    </div>
  </div>
</div>):(
    <Popover id="popover-basic" className='custom-popover' ref={popoverRef}>
      
      <Popover.Body className='p-3 ' onClick={(e) => e.stopPropagation()}>
        <div className='d-flex justify-content-between'>
        <h6>Enter your delivery address</h6>
        <CloseButton onClick={hideDeliveryPopover} className='d-flex d-sm-none'/>
        </div>
       
      <Form className='my-3' onSubmit={handleDeliveryAddressSubmit}>
      <Row>
              <Col xs={12} sm={5}>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D'}}>State/Province</p>
            {/* <Form.Control type='text' placeholder='Enter state/province' style={{border:'1px solid #D7D7D7'}} required  onChange={handleChange} name='state' value={formData.state}/> */}
            <Form.Select style={{width: '100%'}}
        className="custom-select"
        onChange={handleChange}
        value={formData.state}
        name="state"
        required
      > 
      <option value="" disabled>Select a state</option>
    
        <option value='Lagos'>Lagos</option>
        <option value='Ogun'>Ogun</option>

      </Form.Select>

            </Form.Group>
              </Col>
              <Col xs={12} sm={7}>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D'}}>Delivery Area {!formData?.LGA && <span>(Please select a state first)</span>}</p>
            
    <Select
      formatOptionLabel={formatOptionLabel}
      options={lgaOptions}
      onInputChange={handleInputChange}
      onChange={handleSelectChange}
      value={lgaOptions.find(option => option.value === formData.LGA) || ''}
      name="LGA"
      required
      isClearable={true}
      styles={{
        menuList: (provided) => ({
          ...provided,
          maxHeight: '150px', // Adjust the dropdown's max height
          overflowY: 'auto'    // Add scroll to the dropdown menu
        })
      }}
   
    />



            </Form.Group>
              </Col>
             </Row>
            <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D'}}>Street Address{!formData?.LGA && <span>(Please select a state first)</span>}</p>
            <div className="input-group position-relative">
  <Form.Control
  ref={streetAddressAutoCompleteInput}
    type="text"
    placeholder="e.g. 1 Virtue shop, Freshness Street, Lagos"
    style={{ border: '1px solid #D7D7D7', width: '100%' }}
    required
    onChange={handleChange}
    name="streetAddress"
    value={formData.streetAddress}
   
  />
  {formData.streetAddress && (
   <i className='bi bi-x'  onClick={() => setFormData({ ...formData, streetAddress: '' })}
   style={{ top: "2.5%", right:'1%', zIndex: 100, fontSize:'1.5rem', position:'absolute'}}></i>
  )}
</div>

            </Form.Group>

           

            
             <div className='text-center mb-3'>
              <Button type='submit'>Submit</Button>
             </div>
             </Form>
      </Popover.Body>
    </Popover>
  );

 

  return (
    <>
<Navbar fixed='top' className='d-flex d-sm-none justify-content-between px-4'  variant="light" style={{borderBottom:'1px solid #dfdcdc', backgroundColor:'#557B42'}}>
       <Navbar.Brand className='' href='/'>

     <div className="d-flex align-items-center gap-1 ">
    
       <img className="" src={logo2} alt="logo" style={{width:'50px'}}/>
         {/* <p className="mt-3" style={{fontSize:'12px', fontWeight:'700', lineHeight:'15px', color:'#000000'}}>Bringing the <br/>markets to you!</p> */}
 
</div>
    </Navbar.Brand>
<Nav>

    <div className='d-flex gap-2 align-items-center text-white'onClick={() => toggleDeliveryPopover()} ref={deliveryRef}>
      <i className='bi bi-geo-alt'></i>
      {formData.streetAddress ? 
    (<p className='text-decoration-underline text-white my-auto me-1' style={{fontFamily:'Outfit', color:'#353542'}}>
      {formData.streetAddress.length > 20 ? `${formData.streetAddress.substring(0, 30)}...` : formData.streetAddress}
    </p>) : 
    (<p className='text-decoration-underline text-white my-auto me-1' style={{fontFamily:'Outfit', color:'#353542'}}>Enter delivery address</p>)
  } <img src={caretDownWhite} alt='arrow icon'/>
    </div> 
    <Overlay
  show={isDeliveryPopoverVisible} 
  target={deliveryRef.current}
  placement="bottom"
 
>
  {deliveryPopover}
</Overlay>
    </Nav>
  
</Navbar>
    
<Navbar expanded={expanded} expand="sm" sticky='top' variant="light" bg="white" className='d-none d-sm-flex px-4 px-sm-3 px-xl-5 ' style={{borderBottom:'1px solid #dfdcdc'}}>
    
 
    <Navbar.Brand className='ms-0 ms-xl-4' href='/'>
    
    
       <img className="" src={logo} alt="logo" style={{width:'50px'}}/>
       
    </Navbar.Brand>



  
    <Navbar.Toggle aria-controls="navbarSupportedContent" onClick={toggleNavbar}/>
    <Navbar.Collapse id="navbarSupportedContent" >
    <Nav className=' ms-0 ms-md-1 ms-lg-3 me-sm-auto me-md-auto mb-3 mb-sm-0 '>
    <div className='d-flex gap-2 gap-md-1 align-items-center'onClick={() => toggleDeliveryPopover()} ref={deliveryRef}>
      <i className='bi bi-geo-alt'  style={{fontFamily:'Outfit', color:'#353542'}}></i> {formData.streetAddress ? 
    (<p className='text-decoration-underline my-auto me-1' style={{fontFamily:'Outfit', color:'#353542'}}>
      {formData.streetAddress.length > 20 ? `${formData.streetAddress.substring(0, 25)}...` : formData.streetAddress}
    </p>) : 
    (<p className='text-decoration-underline my-auto me-1' style={{fontFamily:'Outfit', color:'#353542'}}>Enter delivery address</p>)
  }<img src={caretDown} alt='arrow icon'/>
    </div>
   <Overlay
  show={isDeliveryPopoverVisible} 
  target={deliveryRef.current}
  placement="bottom"
  rootClose={true}
  onHide={hideDeliveryPopover}
>
  {deliveryPopover}
</Overlay>
    </Nav>
    <Nav className=' ms-0 ms-sm-1 me-sm-1 ms-md-2 me-md-3 ms-xl-3 me-xl-4 mb-3 mb-sm-0'>
    <div className='d-flex align-items-center search-bar position-relative'>
      {!isFocused && searchQuery === '' && (<>
        <img src={search} alt='search' style={{top: '24%', left: '1%'}} className='position-absolute'/>
    
      </>)}
      {searchQuery !== '' && <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchQuery('')}></i>}
      <Form.Control
        style={{border: 'none', backgroundColor: 'transparent'}}
        placeholder='Search..'
        className='w-100'
        value={searchQuery}
        onFocus={handleFocus}
        onBlur={handleBlur} 
        onChange={handleSearchChange}
      />
    </div>

         
      </Nav>
      
      <Nav className='d-block d-md-flex gap-2 me-xxl-5 ms-0 me-0'  onSelect={handleSelect}>
 <div className='d-none d-md-flex my-auto' onClick={()=> { handleShowCart(); trackViewContent('View Cart', userData && userData)}}>
        <button className='position-relative mb-3 mb-sm-0 me-lg-2 border-0 bg-white'> <img src={basket} alt='basket icon'/>
        
        {totalItems > 0 &&
        <span className="position-absolute translate-middle badge rounded-circle super" style={{padding: '3px 5px', border:'1.5px solid #FFFFFF', backgroundColor:'#CE371C', fontSize:'11px'}} >
     {totalItems}
    
    </span>}
    </button>
        </div> 
      
        
        <div className='d-none d-lg-flex d-my-auto' onClick={()=>{handleShowWishlist();trackViewContent('View Wishlist', userData && userData)}}>
  <button className='position-relative mb-3 mb-sm-0 me-xl-2 me-md-1 border-0 bg-white'>
    <img src={love} alt='love'/>
    {((isLoggedIn ? totalWishlistItems : wishlistItems?.length) > 0) && (
      <span className="position-absolute translate-middle badge rounded-circle super2" 
            style={{
              padding: '3px 5px', 
              border:'2px solid #FFFFFF', 
              backgroundColor:'#CE371C', 
              fontSize:'11px',
            }}>
        {isLoggedIn ? totalWishlistItems : wishlistItems?.length}
      </span>
    )}
  </button>
</div>

{isLoggedIn && (
  <>
  <div className='d-flex align-items-center gap-2 gap-md-1 gap-lg-2  px-lg-3 px-md-1 border-line ' onClick={togglePopover} ref={targetRef}>
   {profileImage ? (
    <Image
      roundedCircle
      src={profileImage}
      alt='profile icon'
      style={{
        width: "35px",
        height: "35px", // Adjusted to match the avatar size
        objectFit: "cover",
      }}
    />
  ) : (
    <div
      style={{
        width: '30px', 
        height: '30px', 
        borderRadius: '50%', 
        backgroundColor: 'rgba(70, 155, 18, 0.4)',
        color: '#000000', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight:'500',
        fontSize: '12px', 
      }}
    >
      {
        userData?.firstName ? userData.firstName[0].toUpperCase() : userData.email[0].toUpperCase()
      }
    </div>
  )}
                  <h6 className='d-flex d-sm-none d-md-flex my-auto text-wrap' style={{fontWeight:'500', color:'#58596C'}}>{displayName} </h6>
    
      <Button variant="transparent" bsPrefix="p-0" style={{border:'none',backgroundColor:'white'}} >
        <img src={arrowDown} alt='arrow' />
      </Button>
    
       
  </div>
  <Overlay
      show={isPopoverVisible}
      target={targetRef.current}
      placement="bottom"
      rootClose={true}
      onHide={hidePopover}
    >
      {popover}
    </Overlay>
  </>

)}  

{!isLoggedIn && (
<>
<div>
<div className='d-none d-sm-flex d-lg-none align-items-center gap-2 gap-md-1 gap-lg-2  px-3 border-line ' onClick={toggleUnauthenticatedPopover} ref={unauthenticatedRef}>
<i className="bi bi-person-circle" style={{fontSize:"2rem"}}></i>
</div>
<Overlay
      show={isUnauthenticatedPopoverVisible}
      target={unauthenticatedRef.current}
      placement="bottom"
      rootClose={true}
      onHide={hideUnauthenticatedPopover}
    >
      {unauthenticatedPopover}
    </Overlay>
</div>

<div className='d-block d-sm-none d-lg-flex'>

  <div className='px-xl-3 px-md-2 border-line'>
    <Button className='btn-primary mb-3 mb-sm-0' onClick={() => { handleSelect(); navigate('/login');  trackEvent('Sign in Click', {
    clicked_at: new Date().toISOString(),
    location_clicked: transformPath(window.location.pathname),
    button_clicked: 'Sign in'
  });}}>Sign in</Button>
    </div>
    <div>
    <Button className='btn-outline-primary'  onClick={() => { handleSelect(); navigate('/signup');
    //  const contentId = 'Guest'; 
    //  const contentType = 'View Signup';
    //  trackViewContent(contentId, contentType);
      trackEvent('Create an Account Click', {
    clicked_at: new Date().toISOString(),
    location_clicked: transformPath(window.location.pathname),
    button_clicked: 'Create an account'
  });}}>Create an account</Button>
</div>
</div>
</>
)}
 
       
      </Nav>
    </Navbar.Collapse>
   
  
</Navbar>


<Waitlist showWaitlist={showWaitlist} setShowWaitlist={setShowWaitlist}/>
</>
   
  )
}

export default GuestNavbar


