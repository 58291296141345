import { BrowserRouter, Outlet, useNavigate, useLocation } from 'react-router-dom';
import {useState, useEffect} from 'react';
import './styles.css';
import RoutesConfig from './routes';


import {ToastContainer} from 'react-toastify'
import CartOffCanvas from './pages/Website/Cart'

import Wishlist from './pages/Website/Wishlist';

import Footer from './components/Footer/Footer';
import GuestNavbar, { getParsedLocalStorageItem } from './components/Navbar/Navbar';
import MobileNavbar from './components/Navbar/MobileNavbar';

import LogoutModal from './components/Modal/Logout';

import { Helmet } from 'react-helmet-async';
import useSavePreviousPath from './hooks/useSavePreviousPath';
import { useAppContext } from './AppContext';
import { trackPageView } from './services/tracking';


const ENV_PREFIX = process.env.REACT_APP_ENV_PREFIX || 'production';

export const localStorageSetItem = (key, value) => {
  localStorage.setItem
(`${ENV_PREFIX}_${key}`, value);
};

export const localStorageGetItem = (key) => {
  return localStorage.getItem
(`${ENV_PREFIX}_${key}`);
};

export const localStorageRemoveItem = (key) => {
  localStorage.removeItem(`${ENV_PREFIX}_${key}`);
};

const clearUnprefixedLocalStorageItems = () => {
  const keysToRemove = [];
  
  // Iterate through all keys in local storage
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    
    // Check if the key does not start with the prefix
    if (key && !key.startsWith(ENV_PREFIX)) {
      keysToRemove.push(key); // Collect keys to remove
    }
  }
  
  // Remove each unprefixed key
  keysToRemove.forEach(key => localStorage.removeItem(key));
};

// Call the function on application load


export const transformPath = (path) => {
  // Check for static paths first
  switch (path) {
    case '/':
      return 'Home Page';
    case '/terms':
      return 'Terms of Use';
      case '/privacy-policy':
        return 'Privacy policy';
        case '/shipping-policy':
        return 'Shipping policy';
        case '/refund-policy':
        return 'Refund policy';
    default:
      
      if (path.startsWith('/category/')) {
        return 'Category Listing Page';
      } if (path.startsWith('/products/')) {
        return 'Product Details Page';
      }  else {
        return path; 
      }
  }
};



export const ProtectedRoute = ({ children }) => {

  const userData = getParsedLocalStorageItem('userData');
  const token = localStorageGetItem
('userToken');
  const isLoggedIn = !!userData && !!token;
  const navigate= useNavigate()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login"); // Navigate to the home page
      // setShowLoginModal(true); // Show the login modal
    }
  }, [isLoggedIn, navigate]);


  return isLoggedIn ? <Outlet/> : null;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};




export const Layout = ({ children }) => {
  const location = useLocation();
  const {isMobile} = useAppContext();
  const hideNavbarFooterRoutes = ['/login', '/signup', '/forgot-password','/voucher-receiver'];

  return (
    <>
{!hideNavbarFooterRoutes.includes(location.pathname) && <GuestNavbar />}
 

      <div>{children}</div>

      {!hideNavbarFooterRoutes.includes(location.pathname) && (isMobile ? <MobileNavbar /> : <Footer />)}
    </>
  );
};

export const PathTracker = ({ children }) => {
  useSavePreviousPath();
  return children;
};

export const PreventLoginAccessRoute = () => {
  const userData = getParsedLocalStorageItem('userData');
  const token = localStorageGetItem
('userToken');
  const isLoggedIn = !!userData && !!token;
  const navigate= useNavigate()

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");  // or another "safe" route you'd prefer for logged-in users
    }
  }, [isLoggedIn]);

  // If not logged in, render the login page (or whatever is passed as children)
  return !token ? <Outlet /> : null;
};


function App() {
  const {isMobile}= useAppContext()
  const userData = getParsedLocalStorageItem('userData');
  useEffect(() => {
    clearUnprefixedLocalStorageItems();
  }, [])

  const location = useLocation();

  useEffect(() => {
    // Track page view whenever location (route) changes
    trackPageView(location,userData);
  }, [location]);

  return (
   <>
    
    <Helmet>
  
       
        <meta name="description" content="Affordable food store with fresh food delivery in Lagos and Ogun State. We bring the market to you!" />
        <meta name="keywords" content="Food, Foodstuffs, Fresh tomatoes, Fresh food, Fresh food market, Fresh food delivery, Foodstuff shop in Lagos, Foodstuff delivery shop in Lagos, Affordable food store" />

        <meta property="og:title" content="Virtue's Shop" />
        <meta property="og:description" content="Affordable food store with fresh food delivery in Lagos and Ogun State.  We bring the market to you!" />
        <meta property="og:image" content="https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link" />
        <meta property="og:url" content="https://app.virtuesshop.co" />
        <meta property="og:type" content="website" />


        <meta name="twitter:title" content="Virtue's Shop" />
        <meta name="twitter:description" content="Affordable food store with fresh food delivery in Lagos and Ogun State.  We bring the market to you!" />
        <meta name="twitter:image" content="https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link" />
        <meta name="twitter:card" content="summary_large_image" />

        
        <meta name="author" content="Virtue's Shop" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://app.virtuesshop.co" />

      </Helmet>
  <ToastContainer className='mx-2 mx-md-0 mt-3 mt-md-0' position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover style={{zIndex:'11000'}}/>
 
   <LogoutModal/>
 
   {!isMobile && <CartOffCanvas/>}
   <Wishlist/>

 
   <ScrollToTop/>
<Layout>
  <PathTracker>
  <RoutesConfig isMobile={isMobile} />
    </PathTracker>
    </Layout>
    
    </>
  );
}

export default App;