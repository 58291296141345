import React from 'react'

import facebook from '../../assets/facebook.svg'
import instagram from '../../assets/instagram.svg'
import whatsapp from '../../assets/whatsapp.svg'
import linkedin from '../../assets/linkedin.svg'
import logo from '../../assets/footer-logo.png'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 
  
  return (
    <div style={{backgroundColor:'#5A7F48'}} className='text-white'>
      <div className='py-3 px-3 px-md-5 ms-lg-3'>
       
       <Row className='gap-lg-5 pt-3' id="navbar-example2">
        <Col md={4} >
          <div className='d-flex flex-column gap-2'>  
          <a href='https://virtuesshop.co' target="_blank"  
          rel="noopener noreferrer" className='text-decoration-none text-white'>
          <img src={logo} alt='logo' style={{width:'50px', height:'50px'}} className='' loading="lazy"/></a>
          
<h6 style={{fontWeight:'500', letterSpacing:'2%'}}>Get the latest updates on exclusive offers, delicious recipes, and more! Sign up for our newsletter today.</h6>
<Form.Control type='email' placeholder='Enter your email'/>
<div><Button>Subscribe</Button></div>
</div>
        </Col>
        <Col md={7} className='p'>
          <Row className='gx-0'>
            <Col md={3}>
            <div className='d-flex flex-column gap-1'>
            <h5 className='mb-2'>Company</h5>
            <h6  style={{ cursor: "pointer", fontWeight:'400' }}
            > <a href='https://virtuessohp.co' target="_blank"  
            rel="noopener noreferrer" className='text-decoration-none text-white'>About Us</a></h6>
            <h6  style={{ cursor: "pointer", fontWeight:'400' }}
            onClick={() => navigate("/faq")}>FAQs</h6>
            <h6  style={{ cursor: "pointer", fontWeight:'400' }}
            ><a href='https://chat.whatsapp.com/LQMW9mgRWN2L3LlO8UXFAo' target="_blank"  
            rel="noopener noreferrer" className='text-decoration-none text-white'>Join our Community</a></h6>
            
           <h6  style={{ cursor: "pointer", fontWeight:'400' }} onClick={() => navigate("/profile/orders")}
         >Track your Orders</h6>
          </div>
            </Col>
            <Col md={4}>
            <div className='d-flex flex-column gap-1'>
            <h5 className='mb-2'>Compliance</h5>
            <h6  style={{ cursor: "pointer", fontWeight:'400' }}
            onClick={() => navigate("/privacy-policy")}>Privacy Policy</h6>
            <h6  style={{ cursor: "pointer", fontWeight:'400' }}
            onClick={() => navigate("/terms")}>Terms of Use</h6>
            <h6  style={{ cursor: "pointer", fontWeight:'400' }}  onClick={() => navigate("/refund-policy")}
            >Return & Refund Policy</h6>
            <h6  style={{ cursor: "pointer", fontWeight:'400' }}
             onClick={() => navigate("/shipping-policy")}>Shipping & Delivery Policy</h6>
          </div>
            </Col>
            <Col md={5}>
            <div className='d-flex flex-column gap-1'>
            <h5 className='mb-2'>Contact</h5>
            <div className='d-flex gap-2'>
            <i className='bi bi-telephone'></i>
            <h6 className='d-flex' style={{ cursor: "pointer", fontWeight:'400' }}
            ><a href="tel:+2349167682871" className=" text-white text-decoration-none">+234 9167682871</a></h6>
            </div>
           <div className='d-flex gap-2'>
           <i className='bi bi-envelope'></i>
           <h6  style={{ cursor: "pointer",fontWeight:'400' }}
            > <a
            href="mailto:support@virtuesshop.co"
            className="text-white text-decoration-none"
          >support@virtuesshop.co
          </a></h6>
           </div>
            
           
          <div className='d-flex gap-2'>
          <i className='bi bi-geo-alt'></i>
          <h6  style={{ cursor: "pointer", fontWeight:'400', textDecoration:'' }}
            >No. 2, Ileri-Oluwa Crescent, Ifako-Ijaiye, via
            College Road, Oyemekun Bus-Stop</h6>
          </div>
           
          </div>
            </Col>
          </Row>
          
         
       
        </Col>
       </Row>
      </div>
      <div className='d-flex align-items-center text-center justify-content-between py-3 px-3 px-md-5' style={{borderTop:'1px solid white'}}>
        <div className='d-flex ms-lg-3'>
       <p className='my-auto text-white'style={{fontWeight:'500', fontFamily:'Outfit'}}>Copyright  &copy; Virtue's Shop 2023-2024</p>
          </div>
          <div className='d-flex gap-3 me-lg-5'>
            <a href='https://web.facebook.com/people/VirtuesShop/61556578874811/?mibextid=LQQJ4d' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}><img src={facebook} alt='social-icon'/></a>
            <a href='https://www.instagram.com/virtuesshop_?igsh=cmcxazFqbTQwZmxx' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}><img src={instagram} alt='social-icon'/></a>
            <a href='https://x.com/VirtuesShop?t=pVoMBdmEVgRNA6LEvm4A-Q&s=08' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}} className='text-white '><i className='bi bi-twitter-x' style={{fontSize:'1.2rem'}}></i></a>
            <a href='https://chat.whatsapp.com/LQMW9mgRWN2L3LlO8UXFAo' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}><img src={whatsapp} alt='social-icon' className='' /></a>
            <a href='https://www.linkedin.com/company/virtuesshop/' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}><img src={linkedin} alt='social-icon'/></a>
          </div>

          </div>
    </div>
  )
}

export default Footer